import { createTheme } from "@mui/material/styles";
import { pxToLineHeight } from "./utils/screen";

export const colors = {
  white: { main: "rgba(255, 255, 255, 1)" },
  black: { main: "" },
  grey: { main: "rgba(14, 35, 64, 0.4)", dark: "rgba(14, 35, 64, 0.10)", darker: "rgba(14, 35, 64, 0.6)", light: "rgb(241, 241, 243, 1)", lighter: "rgba(217, 218, 222, 1)" },
  red: { main: "rgba(231, 33, 79, 1)", dark: "rgb(220, 10, 59, 1)", darker: "rgba(213, 0, 50, 1)", light: "rgba(213, 0, 50, 0.6)", lighter: "rgba(231, 33, 79, 0.09)"},
  blue: { main: "#1B365D", base: "rgba(45, 54, 72, 1)u", dark: "rgba(14, 35, 64, 1)", light: "rgba(14, 35, 64, 0.8)", lightMedium: "rgba(14, 35, 64, 0.6)", lighter: "rgba(14, 35, 64, 0.4)" },
  purple: { main: "rgba(194, 44, 116, 1)", dark: "" },
  orange: { main: "", dark: "rgba(231, 103, 62, 1)", next: "" },
  orangeLight: { main: "", dark: "" },
  yellow: { main: "rgba(255, 203, 111, 1)", dark: "" },
  green: { main: "rgba(83, 173, 152, 1)", dark: "", light: "rgba(83, 173, 152, 0.1)" },
};

export const theme = createTheme({
  palette: {
    colors: { ...colors },
    primary: colors.blue,
    secondary: colors.red,
    text: {
      primary: colors.blue.dark,
      fontSize: 24,
    },
    heading: {
      color: colors.blue.dark,
    },
  },

  spacing: (factor) => `${12 * factor}px`,

  shape: {
    borderRadius: 5,
  },

  typography: {
    fontFamily: "\"Inter\", sans-serif",
    small: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.blue.main,
      lineHeight: pxToLineHeight(22, 16),
    },
    comment: {
      fontSize: 12,
      fontWeight: 400,
      color: colors.blue.main,
      lineHeight: 1,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      color: colors.blue.dark,
      lineHeight: pxToLineHeight(22, 16),
    },
    button: {
      textTransform: "none",
      lineHeight: 1,
    },
    info: {
      fontSize: 14,
      fontWeight: 600,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          overflowY: "auto !important",
        },
      },
    },
  },

});

theme.typography.h1 = {
  fontWeight: 700,
  lineHeight: 1,
  [theme.breakpoints.down("lg")]: {
    fontSize: 42,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 32,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: 60,
  },
};

theme.typography.h3 = {
  fontWeight: 700,
  [theme.breakpoints.down("sm")]: {
    fontSize: 28,
    lineHeight: 1.1,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: 36,
    lineHeight: 1.2,
  },
};

theme.typography.h4 = {
  fontWeight: 700,
  fontSize: 28,
};

theme.typography.h5 = {
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 12,
    lineHeight: 1.1,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: 14,
    lineHeight: 1,
  },
};

theme.typography.h6 = {
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    fontSize: 10,
    lineHeight: 1.1,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: 12,
    lineHeight: 1,
  },
};

theme.typography.text = {
  fontWeight: 700,
  [theme.breakpoints.up("sm")]: {
    fontSize: 20,
    lineHeight: 1,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
    lineHeight: 1,
  },
};

theme.typography.heading = {
  fontWeight: 600,
  [theme.breakpoints.up("sm")]: {
    fontSize: 18,
    lineHeight: 1,
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
    lineHeight: 1,
  },
};

theme.components.MuiButton = {
  styleOverrides: {
    root: {
      [theme.breakpoints.down("sm")]: {
        padding: "12px 16px",
        height: "40px",
      },
      [theme.breakpoints.up("sm")]: {
        padding: "4px 16px",
      },
      "> p, * svg": { fontWeight: 500 },
    },
  },
  variants: [
  {
  props: { variant: "secondary" },
  style: {
    color: "white",
    backgroundColor: colors.red.darker,
    height: "48px",
    fontWeight: 600,
    fontSize: 16,
  },
  },
  {
    props: { variant: "secondary-outline" },
    style: {
      color: colors.red.darker,
      border: `1px solid ${colors.red.darker}`,
      height: "48px",
      fontWeight: 600,
      fontSize: 16,
      backgroundColor: colors.red.lighter,
      "&.Mui-disabled": {
        color: colors.red.darker,
      },
    },
    },
  ],
};

  theme.components.MuiTextField = {
    styleOverrides: {
      root: {
        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: colors.red.dark,
        },
        "& .MuiFormLabel-root.Mui-error": {
          color: colors.red.dark,
        },
        "& .MuiFormHelperText-root.Mui-error": {
          color: colors.red.dark,
        },
      },
    },
  };

theme.components.MuiTable = {
  styleOverrides: {
    root: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        display: "block",
        overflowX: "auto",
      },
    },
  },
};

theme.components.MuiChip = {
  styleOverrides: {
    root: {
      borderRadius: "5px",
    },
  },
};
